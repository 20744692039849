import styled from 'styled-components';

export const StyledTiles = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 120px ${(props) => props.theme.appGutter}
		${(props) => props.theme.appGutter} ${(props) => props.theme.appGutter};

	@supports (display: grid) {
		column-gap: 2vw;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		row-gap: 2vw;
	}

	@media screen and (max-width: 800px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media screen and (max-width: 450px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;
