import React, { useEffect } from 'react';
import { animated, useTrail } from 'react-spring';
import { navigate } from 'gatsby';

import useScrollThrottled from './useScrollThrottled';
import { StyledTiles } from './styled';
import Tile from '../Tile/Tile';
import { IProjectData } from '../Project/project.interface';

export default function Tiles({ data }) {
	const projects = data;

	let selectedProject: IProjectData = undefined;
	let visible = false;

	const [trail, setTrail] = useTrail(projects.length, () => ({
		config: { mass: 5, tension: 2000, friction: 170 },
		opacity: 0,
		x: 40,
	}));

	// fix visibility after scroll restoration
	useEffect(() => {
		handleScroll(
			document.documentElement.scrollTop ||
				document.body.scrollTop ||
				window.pageYOffset,
		);
	}, []);

	// store selected project and pass new callback to trail
	function onClick(project: IProjectData) {
		// count animations
		let trailCount = 0;

		function onRest() {
			trailCount += 1;
			// here we use -2 to start early and give it a more "native" feel
			if (trailCount === projects.length - 2) {
				navigate(`/${selectedProject.path}`);
			}
		}

		setTrail({ opacity: 0, x: 40, onRest: onRest });
		selectedProject = project;
	}

	function handleScroll(currentScrollTop) {
		if (currentScrollTop >= window.innerHeight * 0.65) {
			// only reveal if a project hasn't been selected and if it isn't already visible
			if (visible || selectedProject) return;
			visible = true;
			setTrail({ opacity: 1, x: 0 });
		} else {
			// only hide if visible
			if (!visible) return;
			visible = false;
			setTrail({ opacity: 0, x: 40 });
		}
	}

	useScrollThrottled((callbackData) => {
		const { currentScrollTop } = callbackData;
		handleScroll(currentScrollTop);
	});

	return (
		<StyledTiles>
			{trail.map(({ opacity, x }, index) => (
				<animated.div
					onClick={() => onClick(projects[index].node.frontmatter)}
					key={index}
					style={{
						backfaceVisibility: 'hidden',
						opacity: opacity.interpolate((opacity) => opacity),
						transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
					}}
				>
					<Tile data={projects[index].node.frontmatter} />
				</animated.div>
			))}
		</StyledTiles>
	);
}
