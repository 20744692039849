import Img from 'gatsby-image';
import styled from 'styled-components';
import { styledTitle } from '../Layout/theme';
import { Frame } from '../Project/styled';

export const TileFrame = styled(Frame)`
	z-index: 0;
`;

export const TileBackdrop = styled.span`
	background: rgba(20, 20, 20, .6);
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity .2s ease;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

export const TileText = styled.span`
	padding: 2vw;
	@media screen and (max-width: 800px) {
		padding: 3vw;
	}
`;

export const TileTitle = styled.h1`
	${styledTitle};
	display: inline;
	font-size: 1.5em;
	position: relative;
	&:after {
		content: '';
		border-bottom: 3px solid #ff637d;
		border-radius: 1px;
		background: ${(props) => props.theme.colorAccent};
		height: 50%;
		opacity: .8;
		width: 100%;
		position: absolute;
		mix-blend-mode: lighten;
		transform: skew(10deg, -2deg);
		left: 0;
		top: 45%;
	}
`;

export const TileSubtitle = styled.p`
	color: white;
	font-weight: 300;
`;

export const StyledTile = styled.div`
	height: 28vw;
	margin-bottom: 2vw;
	position: relative;
	width: 28vw;
	* {
		cursor: pointer;
	}
	&:hover ${TileFrame} {
		border-color: ${(props) => props.theme.colorAccent};
	}
	&:hover ${TileBackdrop} {
		opacity: 1;
	}
	@media screen and (max-width: 800px) {
		height: 43vw;
		margin-bottom: 5vw;
		width: 43vw;
	}

	@media screen and (max-width: 450px) {
		margin-bottom: 10vw;
		height: 90vw;
		width: 90vw;
	}
`;

export const TileThumbnail = styled(Img)`
	background-position: center;
	background-size: cover;
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 1;
`;
