import React, { Fragment, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useTrail, animated } from 'react-spring';

import HomeHeader from '../components/HomeHeader/home-header';
import Project from '../components/Project';
import { IProjectData } from '../components/Project/project.interface';
import SEO from '../components/SEO/SEO';
import Tiles from '../components/Tiles/Tiles';

export default function Index({ data }) {
	const { siteMetadata: metadata } = data.site;
	const { edges: projects } = data.allMarkdownRemark;

	return (
		<Fragment>
			<SEO>
				<title>{metadata.title}</title>
				<meta name="description" content={metadata.description} />
				<meta property="og:title" content={metadata.title} />
				<meta property="og:description" content={metadata.description} />
			</SEO>

			<HomeHeader />

			<Tiles data={projects} />
		</Fragment>
	);
}
export const pageQuery = graphql`
	query ProjectsQuery {
		site {
			...SiteMetadata
		}
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			limit: 100
		) {
			edges {
				node {
					frontmatter {
						client
						subtitle
						path
						thumbnail {
							childImageSharp {
								fluid(maxWidth: 1200) {
									src
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
						title
					}
				}
			}
		}
	}
`;
