import React from 'react';
import './home-header.scss';
import Logo from '../Logo/Logo';

export default () => (
	<header className="home-header">
		<Logo />
		{/* <h1>
			We make<span className="text-highlight">_slick_</span>digital experiences.
		</h1> */}
	</header>
);
