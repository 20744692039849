import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { styledClient, styledTitle } from '../Layout/theme';

export const StyledProject = styled.article`
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: space-between;
	overflow: hidden;
	padding: ${(props) => props.theme.appGutter};
	scroll-snap-align: start;
	width: 100%;
	@media screen and (max-width: 600px) {
		flex-direction: column;
	}
`;

export const Cover = styled.span`
	cursor: pointer;
	display: block;
	flex: 0 0 30%;
	height: 50vh;
	position: relative;
	z-index: 1;
	@media screen and (max-width: 600px) {
		margin-top: ${(props) => props.theme.navbarHeight};
		flex-basis: 50%;
		width: 100%;
	}
`;

export const Thumbnail = styled(Img)`
	background-position: center;
	background-size: cover;
	height: 100%;
	width: 100%;
	position: absolute;
`;

export const Frame = styled.span`
	border: 2px solid;
	border-color: ${(props) =>
		props.active ? props.theme.colorAccent : 'white'};
	content: '';
	height: 98%;
	left: 10px;
	position: absolute;
	top: 14px;
	width: 99%;
	transition: border 0.2s ease-out;
	z-index: -1;
`;

export const Details = styled.div`
	cursor: pointer;
	flex: 0 0 60%;
	@media screen and (max-width: 600px) {
		margin-top: 40px;
		flex-basis: 50%;
		width: 100%;
	}
`;

export const Title = styled.h1`
	${styledTitle};
	font-size: 36px;
	margin: 10px 0;
	max-width: 60%;
	@media screen and (max-width: 800px) {
		font-size: 30px;
		max-width: 100%;
	}
`;

export const Client = styled.h2`
	${styledClient};
	font-size: 14px;
`;

export const StyledLink = styled(Link)`
	color: inherit;
	text-decoration: none;
`;

export const Subtitle = styled.p`
	font-weight: 200;
	padding: 10px 0;
`;
