import React, { memo } from 'react';

import {
	StyledTile,
	TileThumbnail,
	TileFrame,
	TileBackdrop,
	TileTitle,
	TileSubtitle,
	TileText,
} from './styled';
import { StyledLink } from '../Project/styled';

function Tile(props) {
	const { data: project } = props;

	return (
		<StyledTile>
			{/* <StyledLink to={`/${project.path}`}> */}
			<TileThumbnail fluid={project.thumbnail.childImageSharp.fluid} />
			<TileFrame />
			<TileBackdrop>
				<TileText>
					<TileTitle>{project.title}</TileTitle>
					<TileSubtitle>{project.subtitle}</TileSubtitle>
				</TileText>
			</TileBackdrop>
			{/* </StyledLink> */}
		</StyledTile>
	);
}

export default memo(Tile);
